<template>
	<div>
		<el-dialog title="请选择对象"  top="10%" :visible.sync="dialogshow" :modal-append-to-body="true" :append-to-body="true" :close-on-click-modal="false"
			:width="wwidth+'px'" @closed="closeDialog">
			
			<div>
				<div style="display: inline-block; vertical-align: top; margin-right: 1%;" :class="wwidth==500?'wsml':'wbig'">
					<el-card class="box-card">
						<el-tabs v-model="activeName" @tab-click="tabClick">
							<el-tab-pane label="组织架构" name="one"
								v-if="!type||type=='dept'||type=='notsure'||type=='one'||peizhi">
								<div style="height: 50vh; overflow: auto;">
									<div>
										<el-input placeholder="输入姓名快速查找" v-model="filterText">
										</el-input>
									</div>

									<div v-for="(dept,i1) in DeptData" :key="i1">
										<div class="deptname">
											<div @click="showDept(i1,-1)">
												<span
													:class="['sj',dept.expand?'zk':''] ">▷</span>{{dept.dept_name}}<span
													class="unum"
													v-if="dept.teachers.length>0">{{dept.teachers.length}}人</span>
												<span class="unum " v-if="dept.teachers.length>0&&mult"
													@click.stop="ckAll(i1,-1)">全选</span>
											</div>
											<dept-item :dept="dept" :mult="mult" :prevIdx="i1" :temphead="temphead"
												@ckOne="ckOne" @ckAll="ckAll"></dept-item>
											<!--										<div v-if="dept.expand">
											<div v-for="(sdept,i2) in dept.children" :key="i2"
												style="padding-left: 10px;">
												<div class="sdeptname">
													<div @click="showDept(i1,i2)">
														<span class="sdept">↳</span><span
															:class="['sj',sdept.expand?'zk':''] ">▷</span>{{sdept.dept_name}}
														<span class="unum"
															v-if="sdept.teachers.length>0">{{sdept.teachers.length}}人</span>
														<span class="unum " @click.stop="ckAll(i1,i2)"
															v-if="sdept.teachers.length>0&&mult">全选</span>
													</div>
													<div v-if="sdept.expand">
														<div v-for="(teacher,i) in sdept.teachers" :key="i"
															style="padding-left:10px ;">
															<div class="teacher">
																<img class="userhead" :src="teacher.avatar||temphead"
																	alt="">{{teacher.username}}
																<span class="leader"
																	v-if="sdept.leader==teacher.id">部门领导</span>
																<div class="btnadd" @click.stop="ckOne(teacher)">选择
																</div>
															</div>
														</div>
													</div>
												</div>

											</div>
										</div>-->

										</div>



										<div v-if="dept.expand" v-for="(teacher,i) in dept.teachers" :key="i">
											<div class="teacher">
												<img class="userhead" :src="teacher.avatar||temphead"
													alt="">{{teacher.username}}
												<span class="leader" v-if="dept.leader==teacher.id">部门领导</span>
												<div class="btnadd" @click.stop="ckOne(teacher)">选择</div>
											</div>
										</div>
									</div>
								</div>

							</el-tab-pane>
							<el-tab-pane label="预选人员" name="one"
								v-if="type=='yuxuan'&&!peizhi">
								<div style="height: 50vh; overflow: auto;">
									
									<div>
										
										<div v-for="(teacher,i) in preuserlist" :key="i">
											<div class="teacher">
												<img class="userhead" :src="teacher.avatar||temphead"
													alt="">{{teacher.username}}
												
												<div class="btnadd" @click.stop="ckOne(teacher)">选择</div>
											</div>
										</div>
									</div>
								</div>
							
							</el-tab-pane>
							<el-tab-pane label="部门角色" name="dept_role" v-if="type=='dept_role'">
								<div style="height: 50vh; overflow: auto;">
									<div v-for="(dept,i1) in DeptRoles" :key="i1">
										<div class="deptname">
											<div @click="showDept(i1,-1)">
												<span :class="['sj',dept.expand?'zk':''] ">▷</span>{{dept.dept_name}}

											</div>
											<div v-if="dept.expand">
												<div v-for="(sdept,i2) in dept.children" :key="i2"
													style="padding-left: 10px;">
													<div class="sdeptname">
														<div @click="showDept(i1,i2)">
															<span class="sdept">↳</span><span
																:class="['sj',sdept.expand?'zk':''] ">▷</span>{{sdept.dept_name}}


														</div>
														<div v-if="sdept.expand">
															<div v-for="(teacher,i) in sdept.role_ids" :key="i"
																style="padding-left:10px ;">
																<div class="teacher">
																	<span style="color: orangered;">
																		{{teacher.role_name}}
																	</span>
																	<span>（{{teacher.teachers.length}}人）</span>
																	<span
																		style="font-size: 12px; margin-right: 10px; color: #007AFF;"
																		v-for="(t,a) in teacher.teachers"
																		:key="a">{{t.teacher_name}}</span>

																	<div class="btnadd"
																		@click.stop="ckDeptRole(sdept,teacher)">选择
																	</div>
																</div>
															</div>
														</div>
													</div>

												</div>
											</div>

										</div>



										<div v-if="dept.expand" v-for="(teacher,i) in dept.teachers" :key="i">
											<div class="teacher">
												{{teacher.role_name}}

												<div class="btnadd" @click.stop="ckDeptRole(dept,teacher)">选择</div>
											</div>
										</div>
									</div>
								</div>
							</el-tab-pane>

							<el-tab-pane label="系统角色" name="sys_role" v-if="type=='sys_role'">

								<div style="height: 50vh; overflow: auto;">
									<div v-for="(role,i1) in RoleUsers" :key="i1">
										<div class="deptname">
											<div>
												<span :class="['sj','zk'] ">▷</span>{{role.role_name}}<span class="unum"
													v-if="role.teachers.length>0">{{role.teachers.length}}人</span>
												<span class="unum " v-if="role.teachers.length>0"
													@click.stop="ckRole(role)">选择</span>
											</div>
										</div>



										<div v-for="(teacher,i) in role.teachers" :key="i">
											<div class="teacher">
												<img class="userhead" :src="teacher.avatar||temphead"
													alt="">{{teacher.username}}

											</div>
										</div>
									</div>
								</div>

							</el-tab-pane>

						</el-tabs>

					</el-card>



				</div>

				<div style="width: 48%; display: inline-block; vertical-align: top;"
					v-if="(!type||type=='dept'||type=='notsure'||type=='yuxuan'||type=='one')&&mult">
					<el-card class="box-card">
						<div slot="header" class="clearfix">
							<span>已选人员 ({{ChdUsers.length}}人)</span>
							<el-button size="mini" type="primary" style="float: right;" @click="setUser"
								v-if="ChdUsers.length>0">确认选择</el-button>

							<el-button size="mini" style="float: right; margin-right: 10px;" v-if="ChdUsers.length>0"
								@click="ChdUsers=[]">清除</el-button>


						</div>

						<div style="height: 50vh; overflow: auto;" id="chuserbox">

							<div class="chduser" v-for="(teacher,idx) in ChdUsers" :key="idx">
								<img class="userhead" :src="teacher.avatar||temphead" alt="">{{teacher.username}}
								<div class="remove" @click="removeUser(idx)">
									<img :src="btnremove" />
								</div>
							</div>

						</div>
					</el-card>



				</div>
			</div>
		</el-dialog>

	</div>
</template>

<script>
	import DeptItem from "@/views/sys/components/DeptItem.vue";
	export default {
		//odata 选中的用户或角色数组,module模块名称，type 为dept,dept_role,sys_role，mult是否可多选,peizhi是否在编辑process
		//preuserlist  预选的用户列表

		props: ["odata","preuserlist", "module", "type", "mult","peizhi"],
		components: {
			DeptItem
		},
		data() {
			return {
				temphead: "//school.mmteck.cn/static/temp.png",
				btnremove: "//school.mmteck.cn/static/remove.png",

				dialogshow: true,
				filterText: "",
				activeName: "one",
				ODeptData: [],
				DeptData: [],
				ChdUsers: [],
				RoleUsers: [],
				DeptRoles: [],
				wwidth: 800
			}
		},
		mounted() {
			console.log('this.type',this.type)
			if (this.odata) {
				this.ChdUsers = JSON.parse(JSON.stringify(this.odata));
			}
			if (this.type == "role" || this.type == "dept_role" || this.type == "sys_role") {
				this.wwidth = 500;
			}
			if (!this.type || this.type == 'dept' || this.type == 'notsure'  || this.type ==
				'one'||this.peizhi) {

				if (!this.mult) {
					this.wwidth = 500;
				}

				this.getData()
			} else if( this.type == 'yuxuan'&&!this.peizhi){
				this.DeptData = this.preuserlist
				this.ODeptData = JSON.parse(JSON.stringify(this.preuserlist))
				console.log('this.DeptData');
				console.log(this.DeptData);
			} else if (this.type == 'dept_role') {
				this.getDeptRoles()
				this.activeName = "dept_role"
			} else if (this.type == 'sys_role') {
				this.getRoleUser()
				this.activeName = "sys_role"
			}

		},
		watch: {
			filterText(n, o) {
				if (n) {
					/*let arr = [];
					for (let dept of this.ODeptData) {
						let _dept = JSON.parse(JSON.stringify(dept))
						_dept.teachers = [];
						_dept.children = [];
						for (let teacher of dept.teachers) {
							if (teacher.username.indexOf(n) >= 0) {
								_dept.teachers.push(teacher)
								_dept.expand = true;
							}
						}
						for (let sdept of dept.children) {
							let child = JSON.parse(JSON.stringify(sdept))
							child.teachers = [];
							for (let teacher of sdept.teachers) {
								if (teacher.username.indexOf(n) >= 0) {
									child.teachers.push(teacher)
								}
							}
							if (child.teachers.length > 0) {
								child.expand = true;
								_dept.expand = true;
								_dept.children.push(child)
							}
						}
						if (_dept.teachers.length > 0 || _dept.children.length > 0) {
							arr.push(_dept)
						}

					}
					this.DeptData = arr;*/
					this.DeptData = this.recurSearchUser(this.ODeptData, n)
				} else {
					this.DeptData = this.ODeptData
				}
			}
		},

		methods: {
			getData() {
				this.$http.post("/api/sys_department_tree").then(res => {
					for (let dept of res.data) {
						dept.expand = false
						if (dept.children) {
							for (let sdept of dept.children) {
								sdept.expand = false
								if (sdept.children) {
									for (let ssdept of sdept.children) {
										ssdept.expand = false
										if (ssdept.children) {
											for (let sssdept of ssdept.children) {
												sssdept.expand = false
											}
										}
									}
								}
							}
						}
					}
					this.DeptData = res.data
					this.ODeptData = JSON.parse(JSON.stringify(res.data))

				})
			},
			getRoleUser() {
				this.$http.post("/api/" + this.module + "_role_teacher_tree", {}).then(res => {
					this.RoleUsers = res.data;
				})
			},
			getDeptRoles() {
				this.$http.post("/api/sys_dept_role_tree", {}).then(res => {
					for (let dept of res.data) {
						dept.expand = true
						if (dept.children) {
							for (let sdept of dept.children) {
								sdept.expand = true
								if (sdept.children) {
									for (let ssdept of sdept.children) {
										ssdept.expand = true
										if (ssdept.children) {
											for (let sssdept of ssdept.children) {
												sssdept.expand = true
											}
										}
									}
								}
							}
						}
					}
					this.DeptRoles = res.data;
				})
			},

			tabClick() {
				if (this.activeName == "role" && this.RoleUsers.length == 0) {
					this.getRoleUser()
				} else if (this.activeName == "dept_role" && this.DeptRoles.length == 0) {
					this.getDeptRoles()
				}


			},
			showDept(i1, i2) {

				if (i2 > -1) {
					this.DeptData[i1].children[i2].expand = !this.DeptData[i1].children[i2].expand;

				} else {
					this.DeptData[i1].expand = !this.DeptData[i1].expand;

				}
			},
			ckAll(i1, i2) {

				if (i2 > -1) {
					for (let teacher of i1.teachers) {
						let ext = false;
						for (let t of this.ChdUsers) {
							if (t.id == teacher.id) {
								ext = true;
								break
							}
						}
						if (!ext) {
							this.ChdUsers.push(teacher)
						}
					}

				} else {
					for (let teacher of this.DeptData[i1].teachers) {
						let ext = false;
						for (let t of this.ChdUsers) {
							if (t.id == teacher.id) {
								ext = true;
								break
							}
						}
						if (!ext) {
							this.ChdUsers.push(teacher)
						}
					}

				}

				this.$nextTick(function() {
					$("#chuserbox").scrollTop(99999)
				})
			},
			ckOne(teacher) {
				console.log(teacher)
				if (this.mult) {
					let ext = false;
					for (let t of this.ChdUsers) {
						if (t.id == teacher.id) {
							ext = true;
							break
						}
					}
					if (!ext) {
						this.ChdUsers.push(teacher)
					}
					this.$nextTick(function() {
						$("#chuserbox").scrollTop(99999)
					})
				} else { //只选一个的情况
					this.ChdUsers = [teacher]
					this.setUser()
				}

			},
			ckDeptRole(dept, role) {
				let deptrole = {
					role_id: role.role_id,
					role_name: role.role_name,
					dept_name: dept.dept_name,
					dept_id: dept.id
				}
				this.$emit("getUser", [deptrole])
				this.dialogshow = false
			},
			ckAllRole(i) {

				for (let teacher of this.RoleUsers[i].teachers) {
					let ext = false;
					for (let t of this.ChdUsers) {
						if (t.id == teacher.id) {
							ext = true;
							break
						}
					}
					if (!ext) {
						this.ChdUsers.push(teacher)
					}
				}
			},

			removeUser(idx) {
				this.ChdUsers.splice(idx, 1)
			},
			setUser() {
				this.$emit("getUser", this.ChdUsers)
				this.dialogshow = false

			},
			closeDialog() {
				this.$emit("getUser", null)
				this.dialogshow = false

			},
			ckRole(role) {

				this.$emit("getUser", [role])
				this.dialogshow = false
			},
			recurSearchUser(data, n, result = []) {
				if (!(data.length && n)) {
					return data
				}
				for (let dept of data) {
					let _dept = JSON.parse(JSON.stringify(dept))
					_dept.teachers = _dept.teachers.filter(t => t.username.indexOf(n) >= 0)
					_dept.expand = true
					if (_dept.teachers.length > 0) {
						_dept.children = this.recurSearchUser(_dept.children, n)
						result.push(_dept)
					} else {
						if (_dept.children && _dept.children.length > 0) {
							let children = this.recurSearchUser(_dept.children, n)
							if (children && children.length) {
								result.push({
									..._dept,
									children
								})
							}
						}
					}
				}
				return result
			},

		}
	}
</script>


<style scoped>
	.deptname,
	.teacher,
	.sdeptname {
		line-height: 28px;
		cursor: pointer;
	}

	.wsml {
		width: 100%;
	}

	.wbig {
		width: 49%;
	}

	.sj {
		display: inline-block;
		transition: all 0.3s ease-out;
	}

	.sdept {
		display: inline-block;
		transform: translateY(-5px);
		padding-right: 5px;
		color: #ccc;
	}

	.zk {
		transform: rotateZ(90deg);
	}

	.teacher {
		padding-left: 10px;
		border-bottom: 1px solid #eeeeee;

	}

	.teacher:hover {
		background-color: #eeeeee;
	}

	.userhead {
		width: 20px;
		height: 20px;
		vertical-align: middle;
		border-radius: 10px;
		transform: translateY(-2px);
		margin-right: 5px;
	}

	.btnadd {
		float: right;
		margin-right: 10px;
		color: #007AFF;
	}

	.unum {
		margin-left: 10px;
		font-size: 12px;
		color: #007AFF;
	}

	.chduser {
		padding: 10px 20px;
		border: 1px solid #eeeeee;
		display: inline-block;
		margin: 0 10px 10px 0;
		position: relative;
	}

	.remove {
		position: absolute;
		width: 14px;
		height: 14px;
		background-size: cover;
		top: 0px;
		right: -5px;
		background-color: #fff;
		border-radius: 50%;
		overflow: hidden;
	}

	.remove img {
		width: 100%;
		height: 100%;
		vertical-align: top;
	}

	.leader {
		color: orangered;
		font-size: 12px;
		margin-left: 10px;
	}
</style>